import React from 'react'
import { Link } from 'react-router-dom'

export const InvestmentDisclosure = (props: { isPFM?: boolean }) => {
  const { isPFM } = props
  const pfmDisclosure = (
    <p className='disclosures__text'>
      Note: Strategies beginning with "PFM" are managed by United Capital
      Financial Advisors, LLC (“United Capital”), which in certain instances
      implements model portfolios provided by third-party sub-managers or
      sub-advisors identified in{' '}
      <Link to={'/fee-schedule'}>PFM Portfolio Manager Fee Summary</Link>.
      “Multi-Manager ” indicates strategies for which United Capital has
      discretion to implement model portfolios provided by more than one
      third-party sub-manager or sub-advisor. To the extent that a manager fee
      is listed under a “PFM ” strategy, such fees are the third-party
      sub-manager or sub-advisor fees
    </p>
  )

  return (
    <div>
      <h2 className='disclosures__section-title'>Investments</h2>
      <p className='disclosures__text'>
        The information contained in this material is for general informational
        purposes only and does not constitute an offer, solicitation, invitation
        or recommendation to buy or sell any financial instruments or to provide
        any investment advice or service of any kind.
      </p>
      <p className='disclosures__text'>
        Investing involves risk and investments may lose value. Please consider
        your objectives before investing. Investments are not FDIC insured. Past
        performance does not guarantee future results. Investment outcomes and
        projections are forward-looking statements and hypothetical in nature.
        This report is not to be used as an official books and records
        statement. Information displayed is believed to be accurate, but has not
        been independently verified. Please refer to your custodian statement
        for the most accurate information about your assets.
      </p>
      <p className='disclosures__text'>
        Fees are negotiated with each client individually. Fees noted generally
        do not include supplemental fees charged by sub-managers, or other fees
        (e.g., operational fees). Sub-manager fees may change as sub-manager
        changes are made. A client’s holdings, dollar amount invested, and
        allocation may vary based on actual investments. The percentage of fund
        assets, net of reimbursements, used to pay for operating expenses and
        management fees, including but not limited to, 12b-1 fees,
        administrative fees, and all other asset-based costs incurred by the
        fund, except brokerage costs. Fund expenses are reflected in the fund’s
        NAV. Sales charges are not included in the expense ratio. The expense
        ratio for fund of funds is the aggregate expense ratio as defined as the
        sum of the wrap or sponsor fees plus the estimated weighted average of
        the underlying fund fees. Net reimbursements, the Prospectus Net Expense
        Ratio is collected annually from a fund’s prospectus. The estimated
        expense ratio reflects the approximate internal expenses applicable to
        assets invested in United Capital Personalized Portfolios.
      </p>
      {isPFM ? pfmDisclosure : null}
    </div>
  )
}
